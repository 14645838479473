import React from "react";
import Notes from "../Notes";
import Notes2 from "../Notes2"
const Home = () => {
  return (
    <div className="mt-44">
        <Notes/>   
    </div>
  );
};

export default Home;
